import React from "react";
import { Link } from "react-router-dom";
import { Anchor, Box, Grid, Image, Text } from "grommet";
import LogoSmall from "../static/LogoSmall.png";
import { Button } from "ui-neumorphism";

export default function CustomHeader(props) {
  return (
    <Grid
      columns={{
        count: 3,
        size: "auto",
      }}
      justify="center"
      align="center"
      pad={{ top: "small" }}
      style={{ backgroundColor: "#F1F1F2" }}
    >
      <Box>
        <Anchor size="large" color="dark-1" as={Link} to="/">
          Home
        </Anchor>
      </Box>
      <Box>
        <Image style={{ height: 80 }} src={LogoSmall} />
      </Box>
      <Box direction="row" gap="large">
        <Anchor
          size="large"
          color="dark-1"
          href="https://blog.alexmueller.tech"
        >
          Blog
        </Anchor>
        {/*<Anchor size="large" color="accent-1" as={Link} to="/authenticate">*/}
        {/*  Client*/}
        {/*</Anchor>*/}
        <Link to="/authenticate">
          <Button dark rounded bordered>
            <Text size="medium" weight="bold" style={{ textTransform: "none" }}>
              Login
            </Text>
          </Button>
        </Link>
      </Box>
    </Grid>
  );
}
