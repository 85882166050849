import React, { Component } from "react";
import { Route, Router, Switch } from "react-router-dom";
import history from "./history";
import "./App.css";
import { Grommet, grommet } from "grommet";
import { deepMerge } from "grommet/utils";
import CustomHeader from "./components/CustomHeader";
import Authenticate from "./pages/Authenticate";
import ClientFacingAnalysis from "./pages/ClientFacingAnalysis";
import Store from "./context/Store";
import { overrideThemeVariables, Card, CardContent, H3 } from "ui-neumorphism";
import "ui-neumorphism/dist/index.css";

const theme = deepMerge(grommet, {
  global: {
    colors: {
      brand: "#16C6DF",
      "light-brand": "#9FDBE3",
      "accent-1": "#EE492F",
      focus: "#16C6DF",
    },
  },
  table: {
    body: {
      align: "center",
      pad: { horizontal: "large", vertical: "xsmall" },
      border: "horizontal",
    },
    footer: {
      border: "horizontal",
      pad: { horizontal: "large", vertical: "small" },
    },
    header: {
      align: "center",
      fill: "horizontal",
      pad: { horizontal: "large", vertical: "xsmall" },
      background: {
        color: "accent-1",
        opacity: "strong",
      },
    },
  },
});

class App extends Component {
  componentDidMount() {
    // takes an object of css variable key-value pairs
    overrideThemeVariables({
      "--light-bg": "#F1F1F2",
      "--light-bg-dark-shadow": "#9C9890",
      "--light-bg-light-shadow": "#FFFFFF",
      "--dark-bg": "#F1F1F2",
      "--dark-bg-dark-shadow": "#16C6DF",
      "--dark-bg-light-shadow": "#FFFFFF",
      "--primary": "#16C6DF",
      "--secondary": "#EE492F",
      "--primary-dark": "black",
      "--primary-light": "#c7befd",
      "--g-text-color-dark": "rgba(0, 0, 0, 0.93)",
    });
  }

  render() {
    return (
      <div>
        <Store>
          <Router history={history}>
            <Switch>
              <Grommet theme={theme}>
                <CustomHeader />
                <Route path="/analysis">
                  <ClientFacingAnalysis />
                </Route>
                <Route path="/authenticate">
                  <Authenticate />
                </Route>
                <Route exact path="/">
                  <Card
                    rounded
                    style={{
                      position: "absolute",
                      top: "40%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      padding: 70,
                    }}
                  >
                    <CardContent>
                      <H3>More Soon...</H3>
                    </CardContent>
                  </Card>
                </Route>
              </Grommet>
            </Switch>
          </Router>
        </Store>
      </div>
    );
  }
}

export default App;
