const Reducer = (state, action) => {
  switch (action.type) {
    case "set_project_data":
      return {
        ...state,
        project_data: action.payload,
      };
    case "set_client_id":
      return {
        ...state,
        client_id: action.payload,
      };
    default:
      throw new Error("No case matching");
  }
};

export default Reducer;
