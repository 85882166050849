import React, { useContext } from "react";
import { Context } from "../context/Store";
import { Redirect } from "react-router-dom";
import { Table, Chip } from "ui-neumorphism";

const headers = [
  { text: "Title", align: "left", value: "title" },
  { text: "Payment Type", align: "center", value: "payment_type" },
  { text: "Status", align: "center", value: "status" },
  { text: "Budget", align: "right", value: "budget" },
  { text: "Hour Rate", align: "right", value: "hour_rate" },
  { text: "Hours Worked", align: "right", value: "hours_worked" },
  { text: "Total Amount", align: "right", value: "earned" },
];

export default function ClientFacingAnalysis() {
  const [state, dispatch] = useContext(Context);

  const data = state.project_data.map((project) => {
    let properties = project.properties;
    let dataObj = {};
    dataObj["title"] = properties.Project.title[0].plain_text;

    let payment_type = properties["Payment Type"]["select"]["name"];
    let payment_type_color = "#06D6A0";
    if (payment_type === "Per Project") {
      payment_type_color = "#118AB2";
    }
    dataObj["payment_type"] = (
      <Chip
        style={{ "--light-bg": payment_type_color }}
        color="white"
        size="large"
      >
        {payment_type}
      </Chip>
    );

    let status = properties["Status"]["select"]["name"];
    let status_color = "#073B4C";
    if (status === "Active") {
      status_color = "#EF476F";
    }
    dataObj["status"] = (
      <Chip style={{ "--light-bg": status_color }} color="white" size="large">
        {status}
      </Chip>
    );

    if (payment_type === "Per Hour") {
      dataObj["budget"] = "--";
    } else if (properties.Budget !== undefined) {
      dataObj["budget"] = properties.Budget.number + " €";
    } else {
      dataObj["budget"] = "Not defined";
    }
    dataObj["hour_rate"] =
      Math.round(
        (properties["Hour Rate/Project"]["formula"]["number"] +
          Number.EPSILON) *
          100
      ) /
        100 +
      " €";
    dataObj["earned"] = properties["Total Earned"]["formula"]["number"] + " €";

    let minutes_worked = properties["Total Minutes"]["rollup"]["number"] / 60;
    dataObj["hours_worked"] =
      Math.round((minutes_worked + Number.EPSILON) * 100) / 100 + " h";
    return dataObj;
  });

  if (state.project_data.length === 0) {
    dispatch({ type: "set_project_data", payload: [] });
    return <Redirect to="/authenticate" />;
  }

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Table items={data} headers={headers} />
    </div>
  );
}
