import React, { useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Box, Button, Form, Grid, Text, TextInput } from "grommet";
import { Context } from "../context/Store";
import {
  Card,
  CardHeader,
  CardContent,
  ProgressCircular,
} from "ui-neumorphism";

export default function Authenticate() {
  const [state, dispatch] = useContext(Context);
  const [loading, setLoading] = useState(false);

  const authenticate = (data) => {
    setLoading(true);
    dispatch({ type: "set_client_id", payload: data.token });
  };
  if (state.client_id !== "") {
    fetch("/.netlify/functions/get-project-data?id=" + state.client_id)
      .then((response) => response.json())
      .then((data) =>
        dispatch({ type: "set_project_data", payload: data.results })
      )
      .then(
        state.project_data.length === 0
          ? dispatch({ type: "set_client_id", payload: "" })
          : null
      );
  }

  if (state.project_data.length !== 0) {
    return <Redirect to="/analysis" />;
  }

  return (
    <div
      style={{
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Card rounded width={500} style={{ padding: 20 }}>
        {loading ? (
          <Grid justifyContent="center" pad="medium">
            <Box>
              <ProgressCircular
                indeterminate
                size={94}
                width={8}
                color="var(--primary)"
              />
            </Box>
          </Grid>
        ) : (
          <div>
            <CardHeader>
              <Text size="large" weight="bold">
                Auth Token
              </Text>
            </CardHeader>
            <CardContent>
              <Form onSubmit={({ value }) => authenticate(value)}>
                <TextInput id="textinput-id" name="token" />
                <Box
                  direction="row"
                  justify="between"
                  margin={{ top: "medium" }}
                >
                  <Button type="reset" label="Back" as={Link} to="/" />
                  <Button type="submit" primary label="Authenticate" />
                </Box>
              </Form>
            </CardContent>
          </div>
        )}
      </Card>
    </div>
  );
}
